import React from 'react'
import { Form } from '@unform/web'
import {
  Container, Row, Col, Button,
} from 'reactstrap'
import Select from '../Select'
import Input from '../Input'

import './style.scss'

function handleSubmit(data, { reset }) {
  console.log(data)

  reset()
}

const selectOptions = [
  {
    value: '',
    label: 'Assuntos de seu interesse',
    isDisabled: true,
  },
  {
    value: 'Categoria Um',
    label: 'Categoria Um',
  },
  {
    value: 'Categoria Dois',
    label: 'Categoria Dois',
  },
  {
    value: 'Categoria Três',
    label: 'Categoria Três',
  },
  {
    value: 'Categoria Quatro',
    label: 'A New Step for LGBT Rights - Tuesday July 14th at 17h',
  },
  {
    value: 'Categoria 5',
    label: 'Bedtime Stories with the Obamas - Wednesday July 15th at 11h',
  },
]
const selectLeveling = [
  {
    value: '',
    label: 'Horário de Preferência',
    isDisabled: true,
  },
  {
    value: 'Manhã',
    label: 'Manhã',
  },
  {
    value: 'Tarde',
    label: 'Tarde',
  },
  {
    value: 'Noite',
    label: 'Noite',
  },
]

const RegisterForm = (props) => (
  <section className="contact-form">
    <Container>
      <Row>
        <Col
          lg={props.noSidebar ? { size: 8, offset: 2 } : { size: 7 }}
        >
          {props.title && (
            <h2 className="title text-center">
              Faça sua inscrição agora mesmo!
            </h2>
          )}
          <Form onSubmit={handleSubmit}>
            {props.children}
            {!props.children && (
            <>
              <Input
                name="registerName"
                placeholder="Nome completo"
                className="form-control"
              />
              <Input
                name="registerEmail"
                placeholder="E-mail"
                type="email"
                className="form-control"
              />
              <Input
                name="registerWhatsApp"
                placeholder="Whatsapp"
                className="form-control"
              />
              <Select
                name="registerDebate"
                options={selectOptions}
                defaultValue={selectOptions[0]}
                className="form-select"
              />
              <Input
                name="registerHours"
                placeholder="Número de Horas Semanais"
                className="form-control"
              />
              <Select
                name="registerLeveling"
                options={selectLeveling}
                defaultValue={selectLeveling[0]}
                className="form-select"
              />
            </>
            )}
            <Button
              type="submit"
              className="button orange"
              color="link"
            >
              Enviar
            </Button>
          </Form>
        </Col>
        {!props.noSidebar && (
        <Col lg={{ size: 4, offset: 1 }} className="sidebar">
          <div className="wrapper">
            <h3>Sua escolha</h3>
            <div className="contact-info">
              <ul>
                <li>
                      <strong>Debate Grátis</strong>
                    </li>
                <li>- Benefício 1</li>
                <li>- Benefício 2</li>
                <li>- Benefício 3</li>
                <li>- Benefício 4</li>
                <li>- Benefício 5</li>
                <li>- Benefício 6</li>
              </ul>
            </div>
          </div>
        </Col>
        )}
      </Row>
    </Container>
  </section>
)

export default RegisterForm
