import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import RegisterForm from '../components/RegisterForm'

import '../scss/page-talk-to-us.scss'

const registerPage = () => (
  <Layout>
    <Seo title="Register | Talk of the Town" />
    <Hero text="Register" />

    <section className="contact-form-info">
      <Container>
        <Row>
          <Col>
            <h3>
              Preencha seus dados e entraremos em contato para
              agendar sua primeira aula particular
            </h3>
          </Col>
        </Row>
      </Container>
    </section>
    <RegisterForm />
  </Layout>
)

export default registerPage
