import React, { useRef, useEffect } from "react"
import ReactSelect from "react-select"
import { useField } from "@unform/core"

const Select = ({ name, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, options, error } = useField(
    name
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      option: options,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map(option => option.value)
        }
        if (!ref.state.value) {
          return ""
        }
        return ref.state.value.value
      },
    })
  }, [fieldName, registerField, rest.isMulti, options])

  return (
    <>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && <span style={{ color: "#f00" }}>{error}</span>}
    </>
  )
}

export default Select
